import * as Yup from 'yup';
import { isValidEmail } from '../../../utils/validation';

export const paymentProviderSchema = (isAccountIdRequired = false) =>
  Yup.object({
    account_id: Yup.string().when([], {
      is: () => isAccountIdRequired,
      then: (schema) => schema.required('is required'),
      otherwise: (schema) => schema.notRequired()
    }),
    api_key: Yup.string().required('is required'),
    api_secret: Yup.string().required('is required')
  });

export const User = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .required('Email is required')
    .test('isValidEmail', 'Please enter a valid email', (value) => {
      if (!value) return false;
      return isValidEmail(value);
    })
});
