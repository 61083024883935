import { onError } from '@apollo/client/link/error';
import { timeDifference } from '../utils/convert-date';
import { setContext } from '@apollo/client/link/context';
import { validateAndRefreshToken } from '../context/auth';
import { cloneDeep, cloneDeepWith, forEach, keys } from 'lodash';
import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BASE_URL}/dashboard/graphql`
});

// Create an error link to handle token expiration errors
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    // eslint-disable-next-line no-restricted-syntax
    for (const err of graphQLErrors) {
      switch (err.extensions?.code) {
        case 'UNAUTHENTICATED':
          localStorage.clear();
          window.location.reload();
          break;
        default:
          return;
      }
    }
  }
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const token = await validateAndRefreshToken();
    return {
      headers: {
        ...headers,
        ...(token && { Authorization: `Bearer ${token}` }),
        'x-flexype-timezone': timeDifference()
      }
    };
  } catch (error) {
    console.error('Authentication failed', error);
  }
});

function removeTypeName(value: any) {
  const newValue = cloneDeep(value);
  forEach(keys(newValue), (val) => {
    if (typeof newValue[val] === 'object') {
      newValue[val] = removeTypeName(newValue[val]);
    }
  });

  if (newValue && newValue.__typename) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, ...restValues } = newValue;
    return restValues;
  }
  return newValue;
}

export const omitTypenameDeep = (variables: Record<string, unknown>): Record<string, unknown> => {
  return cloneDeepWith(variables, removeTypeName);
};

const removeTypenameLink = new ApolloLink((operation, forward) => {
  const newOperation = operation;
  newOperation.variables = omitTypenameDeep(newOperation.variables);
  return forward(newOperation);
});

const formatDateLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (response?.data) {
      response.data = omitTypenameDeep(response.data);
    }
    return response;
  });
});

const client = new ApolloClient({
  connectToDevTools: true,
  link: ApolloLink.from([errorLink, formatDateLink.concat(authLink.concat(httpLink)), removeTypenameLink]),
  cache: new InMemoryCache({})
});

export default client;
