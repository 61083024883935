import { Checkbox, RadioGroupItem } from '../../flexyui';
import React from 'react';

type Props = {
  onClick: () => void;
  checked: boolean;
  value: string;
  label: string;
  disabled?: boolean;
};
export const CheckboxWithLabel: React.FC<Props> = ({ onClick, checked, value, label, disabled = false }) => {
  return (
    <div className="flex items-start space-x-2 mt-4 cursor-pointer w-fit">
      <Checkbox checked={checked} value={value} id={value} onClick={onClick} disabled={disabled} className="mt-0.5" />
      <label className="text-black cursor-pointer" htmlFor={value}>
        {label}
      </label>
    </div>
  );
};
