import axios from 'axios';
import { validateAndRefreshToken } from '../context/auth';

export const authAPI = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || ''
});

export const dashboardAPI = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

dashboardAPI.interceptors.request.use(
  async (config) => {
    try {
      const token = await validateAndRefreshToken();
      if (token) config.headers.Authorization = `Bearer ${token}`;
    } catch (error) {
      console.error('Authentication failed', error);
    }
    return config;
  },
  (error) => Promise.reject(error)
);
