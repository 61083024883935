import './App.css';
import { store } from './store';
import MainApp from './app/app';
import snackbarConfig from './config/snackbar';
import { AuthContext } from './context/auth';
import { SnackbarProvider } from 'notistack';
import client from './graphql/apollo-provider';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { PostHogProvider } from 'posthog-js/react';
import useLocalStorage from 'react-use-localstorage';
import { Provider as ReduxProvider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

function App() {
  const [accessToken, setAccessToken] = useLocalStorage('access_token', '');
  const [refreshToken, setRefreshToken] = useLocalStorage('refresh_token', '');

  const options = {
    api_host: 'https://us.posthog.com',
    enable_recording_console_log: true,
    disable_session_recording: true,
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true
      }
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <AuthContext.Provider
            value={{
              accessToken,
              refreshToken,
              setAccessToken,
              setRefreshToken
            }}
          >
            <PostHogProvider apiKey={process.env.REACT_APP_POSTHOG_KEY} options={options}>
              <ReduxProvider store={store}>
                <SnackbarProvider {...snackbarConfig}>
                  <MainApp />
                </SnackbarProvider>
              </ReduxProvider>
            </PostHogProvider>
          </AuthContext.Provider>
        </BrowserRouter>
      </ApolloProvider>
    </QueryClientProvider>
  );
}

export default App;
