/* eslint-disable camelcase */
import { enqueueSnackbar } from 'notistack';
import { ColumnDef } from '@tanstack/react-table';
import * as Unicons from '@iconscout/react-unicons';
import React, { useCallback, useEffect, useState } from 'react';
import {
  CouponType,
  useDeleteCouponMutation,
  useGetCouponsLazyQuery,
  useUpdatePaymentOfferMutation
} from '../../graphql';
import { formatIndianRupees } from '../../utils/format-currency';
import { Button, DataTable, Switch } from '../../components/flexyui';
import { Title } from '../../components/shared/dashboard-title/title';
import { getProductSelection } from '../../utils/discount/handle-selection-data';
import { DataTableColumnHeader } from '../../components/flexyui/DataTable/column-header';
import { DiscountPaymentDialog } from '../../components/discounts/discount-payment-dialog';
import ConfirmationDialog from '../../components/shared/confirmation-dialog/confirmation-dialog';
import { CreateOfferPreview } from '../../components/payment-offers/offers-preview/offer-preview';
import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from '../../components/flexyui/Drawer';

export const PaymentOffers = () => {
  const columns: ColumnDef<any, any>[] = [
    {
      accessorKey: 'code',
      enablePinning: true,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Code" />,
      cell: ({ row }) => {
        return (
          <div className="py-2 px-3 flex items-center font-semibold" onClick={() => onRowClickHandler(row?.original)}>
            {row.original.code}{' '}
          </div>
        );
      }
    },
    {
      accessorKey: 'orders',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Orders" className="flex justify-center" />,
      cell: ({ row }) => {
        return (
          <div className="text-center py-2 px-3" onClick={() => onRowClickHandler(row?.original)}>
            {row.original.orders}
          </div>
        );
      }
    },
    {
      accessorKey: 'gross-sales',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Gross Sales" className="text-right flex justify-end" />
      ),
      cell: ({ row }) => {
        return (
          <div
            className="text-right min-w-fit flex justify-end  px-3 py-2"
            onClick={() => onRowClickHandler(row?.original)}
          >
            {row.original['gross-sales']}
          </div>
        );
      }
    },
    {
      accessorKey: 'discount-amount',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Discount Amount" className="text-right flex justify-end" />
      ),
      cell: ({ row }) => {
        return (
          <div
            className="text-right min-w-fit flex justify-end  px-3 py-2"
            onClick={() => onRowClickHandler(row?.original)}
          >
            {row.original['net-sales']}
          </div>
        );
      }
    },
    {
      accessorKey: 'net-sales',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Net Sales" className="text-right flex justify-end  px-3 py-2" />
      ),
      cell: ({ row }) => {
        return (
          <div
            className="text-right min-w-fit px-3 py-2 flex justify-end"
            onClick={() => onRowClickHandler(row?.original)}
          >
            {row.original['discount-amount']}
          </div>
        );
      }
    },
    {
      accessorKey: 'Action',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Active" className="flex justify-center" />,
      id: 'Active',
      cell: ({ row }) => {
        return (
          <div className="flex py-2 px-3 justify-center items-center">
            <Switch
              checked={row?.original.activeLoading ? !row?.original.active : row?.original.active}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
              onCheckedChange={() => handleActiveStatus(row?.original.row, row.original.id)}
            />
          </div>
        );
      }
    },
    {
      accessorKey: 'Action',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Action" className="flex justify-center" />,
      id: 'Action',
      cell: ({ row }) => {
        return (
          <div className="flex justify-center py-1.5 px-3">
            <Button
              type="submit"
              size="icon"
              variant="icon"
              disabled={false}
              onClick={(e) => {
                e?.stopPropagation();
                setOfferToEdit(row?.original.row);
                setShowPaymentDiscount(true);
              }}
              className="mr-2"
            >
              <Unicons.UilEdit size={'22'} className="p-[2px] text-[#595F74] cursor-pointer" />
            </Button>
            <Button
              type="submit"
              size="icon"
              variant="icon"
              disabled={false}
              onClick={(e) => {
                e?.stopPropagation();
                setOfferToDelete(row?.original?.id || '');
                setShowConfirmationDialog(true);
              }}
              className="text-[#595F74] hover:text-red-500"
            >
              <Unicons.UilTrashAlt size={'22'} className="p-[2px]" />
            </Button>
          </div>
        );
      }
    }
  ];
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const [offerToEdit, setOfferToEdit] = useState<any>();
  const [offerToDelete, setOfferToDelete] = useState<string>('');

  const [showPaymentDiscount, setShowPaymentDiscount] = useState<boolean>(false);

  const [page, setPage] = useState<number>(0);
  const [rowPerPage, setRowPerPage] = useState<number>(25);
  const [rows, setRows] = useState<any>(null);
  const [getPaymentOffers, { data: paymentOfferData, loading, refetch }] = useGetCouponsLazyQuery({
    variables: {
      couponTypes: CouponType.Payment,
      skip: 0,
      limit: rowPerPage
    }
  });

  useEffect(() => {
    const rowData = paymentOfferData?.getCoupons.data.map((row) => {
      return {
        id: row.id,
        active: row.active,
        code: row.code,
        orders: getOrdersCount(row?.metrics?.order_count || 0),
        'gross-sales': `₹${getGrossSales(row?.metrics?.gross_sales || 0)}`,
        'discount-amount': `₹${getDiscountAmount(row?.metrics?.discount || 0)}`,
        'net-sales': `₹${getNetSale(row?.metrics?.net_sales || 0)}`,
        activeLoading: false,
        visibilityLoading: false,
        row
      };
    });
    setRows(rowData);
  }, [paymentOfferData]);

  const [updatePaymentOffer] = useUpdatePaymentOfferMutation();
  const [deletePaymentOfferMutation, { loading: deleting }] = useDeleteCouponMutation();

  const handleDeleteCouponMethod = async () => {
    try {
      await deletePaymentOfferMutation({
        variables: {
          couponId: offerToDelete
        }
      });
      enqueueSnackbar('Hurray! Payment Offer deleted successfully!', {
        variant: 'success'
      });
      await refetch();
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    } finally {
      setOfferToDelete('');
    }
  };

  const getNetSale = (sales: number) => {
    return formatIndianRupees(Number(sales.toFixed(2) || '0'));
  };
  const getDiscountAmount = (discount: number) => {
    return formatIndianRupees(Number(discount.toFixed(2) || '0'));
  };
  const getGrossSales = (sale: number) => {
    return formatIndianRupees(Number(sale.toFixed(2) || '0'));
  };
  const getOrdersCount = (count: number) => {
    return count || '0';
  };

  const updateColumnData = (type: 'success' | 'error', rowId: string) => {
    const rowData = paymentOfferData?.getCoupons.data.map((row) => {
      const loadingState = row.id === rowId ? true : false;
      const activeData = {
        id: row.id,
        active: row.active,
        code: row.code,
        orders: getOrdersCount(row?.metrics?.order_count || 0),
        'gross-sales': `₹${getGrossSales(row?.metrics?.gross_sales || 0)}`,
        'discount-amount': `₹${getDiscountAmount(row?.metrics?.discount || 0)}`,
        'net-sales': `₹${getNetSale(row?.metrics?.net_sales || 0)}`,
        activeLoading: type === 'success' ? loadingState : false,
        visibilityLoading: false,
        row
      };
      return activeData;
    });
    setRows(rowData);
  };

  const handleActiveStatus = async (updatedData: any, rowId: string) => {
    const {
      id,
      metrics,
      applies_on_each_item,
      disable_cod,
      payment_offers,
      purchase_requirement,
      application,
      combine,
      discount_amount,
      discount_max_cap,
      application_type,
      coupon_type,
      platform,
      tags,
      description,
      title,
      purchase_activity,
      purchase_activity_period,
      user_specific_metadata,
      add_on,
      ...rest
    } = updatedData;
    const { type } = application;
    const { amount, discount_max_cap: discount, active, ...filteredPaymentOffers } = payment_offers;
    const { collection_selections, product_selections, ...restPurchaseRequirements } = purchase_requirement;
    try {
      updateColumnData('success', rowId);
      await updatePaymentOffer({
        variables: {
          couponId: updatedData?.id,
          data: {
            ...rest,
            application: { type },
            purchase_requirement: {
              ...restPurchaseRequirements,
              products: getProductSelection(updatedData?.purchase_requirement?.products)
            },
            discoverable: updatedData?.discoverable,
            active: !updatedData.active,
            payment_offers: filteredPaymentOffers
          }
        }
      });
      enqueueSnackbar('Hurray! Payment Offer activation updated successfully!', {
        variant: 'success'
      });
    } catch (error) {
      updateColumnData('error', rowId);
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }
    await refetch();
  };

  const onRowClickHandler = (item: any) => {
    if (item?.row) {
      setShowDetailsModal(true);
      setOfferToEdit(item?.row);
    }
  };

  const handleConfirm = () => {
    handleDeleteCouponMethod();
    setShowConfirmationDialog(false);
  };

  function handleChangePage(event: any, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPageByValue(value: any) {
    setRowPerPage(parseInt(value, 10));
    setPage(0);
  }

  const handleSearch = (skip: number, limit: number) => {
    getPaymentOffers({
      variables: {
        couponTypes: CouponType.Payment,
        skip,
        limit
      }
    });
  };

  const handleGetPaymentOffers = useCallback(handleSearch, []);

  useEffect(() => {
    handleGetPaymentOffers(rowPerPage * page, rowPerPage);
  }, [rowPerPage, page]);

  return (
    <>
      <div className="mx-4">
        <Title title="Payment Offers">
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              setOfferToEdit(null);
              setShowPaymentDiscount(true);
            }}
            disabled={loading}
          >
            <Unicons.UilPlus size={18} className="mr-2" />
            Add Offer
          </Button>
        </Title>

        <div className="mt-2">
          <DataTable
            columns={columns}
            data={rows}
            showFilterInput={false}
            showPagination={true}
            onRowClick={onRowClickHandler}
            rowSelection={false}
            rowsPerPageOptions={[25, 50, 100]}
            rowsPerPage={rowPerPage}
            count={paymentOfferData?.getCoupons?.page_info?.total_count || 0}
            page={page}
            setPage={setPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPageByValue}
          />
        </div>
      </div>

      {showPaymentDiscount && (
        <DiscountPaymentDialog
          open={showPaymentDiscount}
          setOpen={setShowPaymentDiscount}
          offerDetails={offerToEdit}
          setOfferDetails={setOfferToEdit}
          refetch={refetch}
        />
      )}

      <ConfirmationDialog
        showModal={showConfirmationDialog}
        setShowModal={setShowConfirmationDialog}
        onSave={handleConfirm}
        headerText="Delete Offer?"
        text="This Action will delete the selected offer"
        confirmButtonText="Delete"
        confirmActionVariant="destructive"
        loading={deleting}
      />

      <Drawer direction="right" open={showDetailsModal} onOpenChange={(value: boolean) => setShowDetailsModal(value)}>
        <DrawerContent>
          <DrawerHeader>
            <div className="flex justify-between items-center mb-4">
              <DrawerTitle>Payment Offer Details</DrawerTitle>
              <Button size="icon" variant="icon" onClick={() => setShowDetailsModal(false)}>
                <Unicons.UilTimes className="text-[#2F72FF] cursor-pointer" />
              </Button>
            </div>
            <DrawerDescription className="text-black text-left">
              <CreateOfferPreview activeStep={4} selectedPaymentOffer={offerToEdit} isEdit={showPaymentDiscount} />
            </DrawerDescription>
          </DrawerHeader>
        </DrawerContent>
      </Drawer>
    </>
  );
};
