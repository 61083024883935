import jwtDecode from 'jwt-decode';
import { createContext, useContext } from 'react';
import { loginWithMerchantId } from '../network/authentication/authentication';

interface AuthContextProps {
  accessToken: string | null;
  refreshToken: string | null;
  setAccessToken: (type: string) => void;
  setRefreshToken: (type: string) => void;
}

export const AuthContext = createContext({} as AuthContextProps);

export function useAuth() {
  return useContext(AuthContext);
}

let refreshPromise: any = null;

export const validateAndRefreshToken = async (): Promise<string | null> => {
  const accessToken = localStorage.getItem('access_token') || '';
  const refreshToken = localStorage.getItem('refresh_token');

  const decodedToken: any = jwtDecode(accessToken);
  const currentTime = Math.floor(Date.now() / 1000);
  const isTokenExpired = decodedToken?.exp < currentTime;

  if (isTokenExpired) {
    if (refreshToken) {
      if (!refreshPromise) {
        refreshPromise = (async () => {
          try {
            const response = await loginWithMerchantId(decodedToken.merchant_id, refreshToken);
            const tokens = response?.data?.data?.token_data;
            const newAccessToken = tokens?.access_token;
            const newRefreshToken = tokens?.refresh_token;

            localStorage.setItem('access_token', newAccessToken);
            localStorage.setItem('refresh_token', newRefreshToken);
            return newAccessToken;
          } catch (error) {
            console.error('Token refresh failed:', error);
            localStorage.clear();
            throw error;
          } finally {
            refreshPromise = null;
          }
        })();
      }

      return await refreshPromise;
    } else {
      console.error('No refresh token available');
      localStorage.clear();
      return null;
    }
  }

  return accessToken;
};
