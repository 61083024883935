import React from 'react';
import classNames from 'classnames';
import { Label } from '../Label';

interface Props {
  text?: string;
  variant?:
    | 'info'
    | 'danger'
    | 'warning'
    | 'success'
    | 'primary'
    | 'simple'
    | 'draft'
    | 'secondary'
    | 'tertiary'
    | 'light';
  show?: boolean;
  icon?: React.ReactNode;
  className?: string;
}

export const Chip: React.FC<Props> = ({ text = '', icon, variant = 'primary', show = true, className = '' }) => {
  return (
    <div
      className={classNames('w-fit rounded-xl overflow-hidden', {
        'bg-[#FEF0CD]': variant === 'warning',
        'bg-primary': variant === 'primary',
        'text-[#0238a8] bg-[#e3edfc]': variant === 'simple',
        'text-[#083D25]  bg-[#CDFEE1]': variant === 'success',
        'bg-[#EEE]': variant === 'draft',
        'bg-[#e8dafa]': variant === 'secondary',
        'bg-[#ddf7ff]': variant === 'tertiary',
        'bg-[#e0e0e0]': variant === 'light',

        hidden: show === false,
        'inline-flex': show === true
      })}
    >
      <Label
        className={classNames(
          'px-3 text-[11px] font-medium py-[2px] border-border shadow-md overflow-hidden flex items-center',
          {
            'text-white': variant === 'primary',
            'text-[#595F74]': variant === 'draft'
          },
          className
        )}
      >
        {icon && <>{icon}</>}
        {text}
      </Label>
    </div>
  );
};
