import React from 'react';
import { ApplicationType, CouponType, Platform } from '../../../graphql';
import { Chip } from '../../flexyui/Chip/index';
import * as Unicons from '@iconscout/react-unicons';
import { ButtonWithInfo } from '../../flexyui/ButtonWithInfo';

type Props = {
  data: any;
};

export const CouponChip: React.FC<Props> = ({ data }) => {
  return (
    <>
      {(data.original.row?.coupon_type === CouponType.AddOn
        ? 1
        : (data.original.row?.application_type === ApplicationType.Automatic ? 1 : 0) +
          (data.original.row?.combine ? 1 : 0)) +
        (data.original.row?.platform === Platform.Shopify ? 1 : 0) +
        (data.original.row?.coupon_type === CouponType.Bxgy ? 1 : 0) +
        (data.original.row?.coupon_type === CouponType.PurchaseHistory ? 1 : 0) >=
      2 ? (
        <>
          {/* TODO: Add Tooltip on these Chips */}
          <div className="ml-3 flex items-center gap-1">
            {data.original.row?.coupon_type === CouponType.AddOn ? (
              <ButtonWithInfo heading="Freebie" description="" simple={true} side="top">
                <Chip variant="primary" className="text-white p-[5px]" icon={<Unicons.UilGift size={13} />} />
              </ButtonWithInfo>
            ) : (
              <>
                {data.original.row?.application_type === ApplicationType.Automatic && (
                  <ButtonWithInfo heading="Automatic" description="" simple={true} side="top">
                    <Chip
                      variant="success"
                      className="text-[#009a07] p-[5px]"
                      icon={<Unicons.UilBoltAlt size={13} />}
                    />
                  </ButtonWithInfo>
                )}
                {data.original.row?.combine && (
                  <ButtonWithInfo heading="Combines" description="" simple={true} side="top">
                    <Chip
                      variant="warning"
                      className="text-[#aa5900] p-[5px]"
                      icon={<Unicons.UilMinusPath size={13} />}
                    />
                  </ButtonWithInfo>
                )}
              </>
            )}
            {data.original.row?.platform === Platform.Shopify && (
              <ButtonWithInfo heading="Shopify" description="" simple={true} side="top">
                <Chip variant="simple" className="text-[#696969] p-[5px]" icon={<Unicons.UilImport size={13} />} />
              </ButtonWithInfo>
            )}
            {data.original.row?.coupon_type === CouponType.Bxgy && (
              <ButtonWithInfo heading="Buy X Get Y" description="" simple={true} side="top">
                <Chip variant="secondary" className="text-[#705b8c] p-[5px]" icon={<Unicons.UilCapsule size={13} />} />
              </ButtonWithInfo>
            )}
            {data.original.row?.coupon_type === CouponType.PurchaseHistory && (
              <ButtonWithInfo heading="Purchase Driven" description="" simple={true} side="top">
                <Chip variant="tertiary" className="text-[#457484] p-[5px]" icon={<Unicons.UilArchive size={13} />} />
              </ButtonWithInfo>
            )}
          </div>
        </>
      ) : (
        <>
          {data.original.row?.coupon_type === CouponType.AddOn ? (
            <div className="ml-3">
              <Chip
                text="Freebie"
                variant="light"
                className="text-blue-900"
                icon={<Unicons.UilCapsule size={13} className="mr-1" />}
              />
            </div>
          ) : (
            <>
              {data.original.row?.application_type === ApplicationType.Automatic && (
                <div className="ml-3">
                  <Chip
                    text={'Automatic'}
                    variant="success"
                    className="text-[#009a07]"
                    icon={<Unicons.UilBoltAlt size={13} className="mr-1" />}
                  />
                </div>
              )}
              {data.original.row?.combine && (
                <div className="ml-3">
                  <Chip
                    text={'Combines'}
                    variant="warning"
                    className="text-[#aa5900]"
                    icon={<Unicons.UilMinusPath size={13} className="mr-1" />}
                  />
                </div>
              )}
            </>
          )}
          {data.original.row?.platform === Platform.Shopify && (
            <div className="ml-3">
              <Chip
                text={'Shopify'}
                variant="simple"
                className="text-[#696969]"
                icon={<Unicons.UilImport size={13} className="mr-1" />}
              />
            </div>
          )}
          {data.original.row?.coupon_type === CouponType.Bxgy && (
            <div className="ml-3">
              <Chip
                text={'Buy X Get Y'}
                variant="secondary"
                className="text-[#705b8c]"
                icon={<Unicons.UilCapsule size={13} className="mr-1" />}
              />
            </div>
          )}
          {data.original.row?.coupon_type === CouponType.PurchaseHistory && (
            <div className="ml-3">
              <Chip
                text={'Purchase Driven'}
                variant="tertiary"
                className="text-[#457484]"
                icon={<Unicons.UilArchive size={13} className="mr-1" />}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
