import React, { useState } from 'react';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import * as Unicons from '@iconscout/react-unicons';
import { GetMerchantListQuery } from '../../graphql';
import { CustomAvatar } from '../shared/custom-avatar';
import useLoginWithMerchantId from '../../hooks/use-login-with-merchant';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, Input } from '../flexyui';

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  merchantList: GetMerchantListQuery['getMerchantList'];
};

export const AllStoresDialog: React.FC<Props> = ({ open, setOpen, merchantList }) => {
  const loginMutation = useLoginWithMerchantId();
  const merchantId = useSelector((state: RootState) => state.main.store);
  const [query, setQuery] = useState<string>('');

  const updatedMerchantList = merchantList.filter(
    (store) => store.id !== merchantId && store.name.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <Dialog open={open} onOpenChange={(value: boolean) => setOpen(value)}>
      <DialogContent className="!gap-0" close={true}>
        <div>
          <DialogHeader>
            <DialogTitle>
              <p>Select Store</p>
            </DialogTitle>
            <div className="sticky z-10 bg-white top-0 p-3 !m-0 border-b-[1px]">
              <Input
                type="text"
                placeholder="Search store"
                className="!m-0"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <DialogDescription className="!p-0 !m-0 text-[#2A324C]">
              <div className="w-full h-[60vh] max-h-[500px] overflow-y-auto scroll-smooth px-2">
                {updatedMerchantList.map((store) => (
                  <div
                    onClick={() => {
                      setOpen(false);
                      loginMutation.mutate(store.id);
                    }}
                    className="flex items-center justify-between p-2 cursor-pointer m-auto my-1 rounded-xl hover:bg-gray-100 transition-all ease-linear group"
                  >
                    <div className="flex items-center gap-3 group-hover:gap-4 transition-all duration-200 ease-in-out">
                      <CustomAvatar name={store.name} />
                      <div className="text-sm font-medium">{store.name}</div>
                    </div>
                    <Unicons.UilAngleRight
                      size={20}
                      className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out"
                    />
                  </div>
                ))}
                {updatedMerchantList.length === 0 && (
                  <div className="text-gray-500 text-center mt-4">No stores found.</div>
                )}
              </div>
            </DialogDescription>
          </DialogHeader>
        </div>
      </DialogContent>
    </Dialog>
  );
};
