import React from 'react';
import { DiscountCode } from '../../shared/discount/discount-setup/discount-code';
import { DiscountFreeItems } from '../../shared/discount/discount-setup/discount-free-items';

export const DiscountFreebieSetup = () => {
  return (
    <>
      <DiscountCode />
      <hr />
      <DiscountFreeItems />
    </>
  );
};
