import jwtDecode from 'jwt-decode';
import { AppDispatch } from '../store';
import { useDispatch } from 'react-redux';
import { useAuth } from '../context/auth';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Login } from '../pages/login/login';
import { posthogIdentity } from '../utils/log-event';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ResetPassword } from '../pages/reset-password/reset-password';
import { ForgotPassword } from '../pages/forgot-password/forgot-password';
import AuthenticatedApp from '../pages/authenticated-app/authenticated-app';
import { setStore, setStoreName, setUserEmail, setUserName, setUserType } from '../store/main-slice';

function MainApp() {
  const location = useLocation();
  const { accessToken, setAccessToken, setRefreshToken } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const [forgotPasswordActive, setForgotPasswordActive] = useState(false);

  // Extract query parameters
  const queryAccessType = searchParams.get('access_type');
  const queryAccessToken = searchParams.get('access');
  const queryRefreshToken = searchParams.get('refresh');
  const resetPasswordToken = searchParams.get('token');

  // Handle restricted access type
  useEffect(() => {
    if (queryAccessType === 'restricted') {
      enqueueSnackbar('Access denied! You do not have permissions to open this app', {
        variant: 'error'
      });
      setSearchParams((params) => {
        params.delete('access_type');
        return params;
      });
    }
  }, [queryAccessType]);

  // Handle access and refresh tokens
  useEffect(() => {
    if (queryAccessToken) {
      setAccessToken(queryAccessToken);
      setSearchParams((params) => {
        params.delete('access');
        return params;
      });
    }

    if (queryRefreshToken) {
      setRefreshToken(queryRefreshToken);
      setSearchParams((params) => {
        params.delete('refresh');
        return params;
      });
    }
  }, [queryAccessToken, queryRefreshToken]);

  // Decode access token if available
  useEffect(() => {
    if (accessToken) {
      const user: any = jwtDecode(accessToken);
      dispatch(setStore(user.payload.merchant_id));
      dispatch(setStoreName(user.payload.merchant_name));
      dispatch(setUserName(user.payload.name));
      dispatch(setUserEmail(user.payload.email));
      dispatch(setUserType(user.payload.user_type));
      posthogIdentity({
        merchantId: user.payload.merchant_id,
        merchantName: user.payload.merchant_name
      });
    }
  }, [accessToken]);

  if (location.pathname === '/reset-password' && !!resetPasswordToken)
    return <ResetPassword token={resetPasswordToken} />;
  if (forgotPasswordActive) {
    return <ForgotPassword setForgotPasswordActive={setForgotPasswordActive} />;
  }
  if (!accessToken) {
    return <Login setForgotPasswordActive={setForgotPasswordActive} />;
  }
  return <AuthenticatedApp />;
}
export default MainApp;
