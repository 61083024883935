import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
  Skeleton
} from '../../flexyui';
import {
  useGetUsersQuery,
  useCreateDashboardUserMutation,
  useDeleteDashboardUserMutation,
  DashboardUserType
} from '../../../graphql';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { enqueueSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { CustomAvatar } from '../../shared/custom-avatar';
import { User } from '../../../utils/validation-schemas/settings';
import ConfirmationDialog from '../../shared/confirmation-dialog/confirmation-dialog';
import { ReactComponent as LoadingIcon } from '../../../assets/images/loading.svg';

export const UserPermissions = () => {
  const userType = useSelector((state: RootState) => state.main.userType);

  const [userToDelete, setUserToDelete] = useState<string | null>(null);
  const [openUserDialog, setOpenUserDialog] = useState<boolean>(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const { data, loading, refetch } = useGetUsersQuery();
  const [addUser] = useCreateDashboardUserMutation();
  const [deleteUser] = useDeleteDashboardUserMutation();

  const ownerDetails = data && data.getUsers.find((user) => user.user_type === DashboardUserType.Owner);
  const staffList = data && data.getUsers.filter((user) => user.user_type === DashboardUserType.Staff);

  const handleSave = async (values: any) => {
    try {
      await addUser({
        variables: {
          userData: values
        }
      });

      enqueueSnackbar('User has been added successfully!', {
        variant: 'success'
      });

      await refetch();
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    } finally {
      setOpenUserDialog(false);
    }
  };

  const handleDeleteUser = async () => {
    if (!userToDelete) return;

    try {
      await deleteUser({
        variables: {
          dashboardUserId: userToDelete
        }
      });

      enqueueSnackbar('User has been deleted successfully!', {
        variant: 'success'
      });

      await refetch();
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    } finally {
      setUserToDelete(null);
      setShowConfirmationDialog(false);
    }
  };

  const formatLastSync = (timestamp: any) => {
    if (!timestamp) return 'Never';

    return new Date(parseInt(timestamp.toString(), 10)).toLocaleString('en-IN', {
      timeZone: 'Asia/Kolkata',
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const initialValues = useMemo(() => {
    return {
      name: '',
      email: ''
    };
  }, []);

  return (
    <>
      {ownerDetails && (
        <Card className="p-0 bg-[#fbfbfb] mb-4 h-fit border-0 shadow-none sm:shadow-[0px_4px_20px_rgba(0,0,0,0.05)] sm:bg-card sm:p-4 sm:border">
          <div className="flex items-center justify-between mb-4">
            <div>
              <div className="font-medium">Store owner</div>
              <div className="text-[#888D9B] text-xs">Note: some owner permissions can't be assigned to staff</div>
            </div>
          </div>
          <div className="text-xs border border-muted px-4 py-4 rounded-[6px] mb-3 last:mb-0">
            <div className="flex items-center gap-2">
              <CustomAvatar name={ownerDetails.name} />
              <div>
                <div className="text-sm font-semibold">{ownerDetails.name}</div>
                <div>{ownerDetails.email}</div>
              </div>
            </div>
            <div className="mt-3">Last login: {formatLastSync(ownerDetails.last_logged_in)}</div>
          </div>
        </Card>
      )}

      <Card className="p-0 bg-[#fbfbfb] mb-4 h-fit border-0 shadow-none sm:shadow-[0px_4px_20px_rgba(0,0,0,0.05)] sm:bg-card sm:p-4 sm:border">
        <div className="flex items-center justify-between mb-4">
          <div>
            <div className="font-medium">Users and Permissions</div>
            <div className="text-[#888D9B] text-xs">Manage user access for your platform.</div>
          </div>
          {(staffList?.length || 0) > 0 && (
            <Button
              variant="primary"
              size="sm"
              disabled={userType === DashboardUserType.Staff}
              onClick={() => setOpenUserDialog(true)}
            >
              <Unicons.UilPlus size={18} className="mr-2" />
              Add user
            </Button>
          )}
        </div>

        {loading &&
          [0, 1].map(() => (
            <div className="border border-muted px-4 py-4 rounded-[6px] mb-3 last:mb-0">
              <div className="flex items-center justify-between">
                <Skeleton className="w-[150px] !m-0" />
                <div className="flex items-center gap-2">
                  <Skeleton className="!m-0 h-8 w-8" />
                  <Skeleton className="!m-0 h-8 w-8" />
                </div>
              </div>
              <div className="mt-4">
                <Skeleton className="w-full sm:w-[450px] !m-0 !mb-2" />
                <Skeleton className="w-[70%] sm:w-[300px] !m-0" />
              </div>
            </div>
          ))}

        {staffList?.map((user) => (
          <div className="text-xs border border-muted px-4 py-4 rounded-[6px] mb-3 last:mb-0">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <CustomAvatar name={user.name} />
                <div>
                  <div className="text-sm font-semibold">{user.name}</div>
                  <div>{user.email}</div>
                </div>
              </div>
              <Button
                size="icon"
                variant="icon"
                disabled={userType === DashboardUserType.Staff}
                onClick={() => {
                  setUserToDelete(user.id);
                  setShowConfirmationDialog(true);
                }}
                className="text-[#595F74] hover:text-red-500"
              >
                <Unicons.UilTrashAlt size="22" className="p-[2px]" />
              </Button>
            </div>
            <div className="mt-3">Last login: {formatLastSync(user.last_logged_in)}</div>
          </div>
        ))}

        {staffList?.length === 0 && (
          <div className="border border-muted px-4 py-4 mt-2 rounded-[6px]">
            <div className="text-center my-4">
              <Button
                variant="primary"
                size="sm"
                disabled={userType === DashboardUserType.Staff}
                onClick={() => setOpenUserDialog(true)}
              >
                <Unicons.UilPlus size={18} className="mr-2" />
                Add user
              </Button>
            </div>
          </div>
        )}

        {!data?.getUsers && !loading && (
          <div className="text-center my-40">
            <div className="text-gray-300 text-base">Not Available</div>
          </div>
        )}
      </Card>

      <ConfirmationDialog
        showModal={showConfirmationDialog}
        setShowModal={setShowConfirmationDialog}
        onSave={handleDeleteUser}
        text="This action will delete the user."
      />

      <Formik<{
        name: string;
        email: string;
      }>
        enableReinitialize
        initialValues={initialValues}
        validateOnChange={true}
        validateOnMount={true}
        validateOnBlur={true}
        validationSchema={User}
        onSubmit={handleSave}
      >
        {({ errors, touched, getFieldProps, submitForm, resetForm, isSubmitting }) => {
          return (
            <Dialog open={openUserDialog} onOpenChange={(value) => setOpenUserDialog(value)}>
              <DialogContent className="!gap-0" size="sm" close={true}>
                <div>
                  <DialogHeader>
                    <DialogTitle>
                      <p>Add user</p>
                    </DialogTitle>
                    <DialogDescription className="!p-0 !m-0">
                      <div className="p-4">
                        <div>
                          <Label size="paragraph">Name</Label>
                          <Input
                            {...getFieldProps('name')}
                            error={touched.name && !!errors.name}
                            errorMessage={errors.name}
                            className="text-[#121b38] mt-1"
                          />
                        </div>
                        <div className="mt-3">
                          <Label size="paragraph">Email</Label>
                          <Input
                            {...getFieldProps('email')}
                            error={touched.email && !!errors.email}
                            errorMessage={errors.email}
                            className="text-[#121b38]"
                          />
                        </div>
                      </div>
                    </DialogDescription>
                  </DialogHeader>
                </div>
                <DialogFooter>
                  <Button variant="outline" size="md" onClick={() => setOpenUserDialog(false)}>
                    Cancel
                  </Button>
                  <Button
                    variant="default"
                    size="md"
                    disabled={Object.keys(errors).length > 0 || isSubmitting}
                    onClick={async () => {
                      await submitForm();
                      resetForm();
                    }}
                  >
                    {isSubmitting ? <LoadingIcon height={20} className={'animate-spin text-white'} /> : 'Add'}
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};
