import {
  setSidebarState,
  setConfirmationDialog,
  setCancelButtonClicked,
  setRouteLink,
  setPageProgress
} from '../../store/main-slice';
import { Button } from '../flexyui';
import React, { useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import * as Unicons from '@iconscout/react-unicons';
import LayoutWrapper from '../layout/layout-wrapper';
import { DashboardAction } from './dashboard-action';
import { AppDispatch, RootState } from '../../store';
import { AllStoresDialog } from './all-stores-dialog';
import { useDispatch, useSelector } from 'react-redux';
import { useGetMerchantListQuery } from '../../graphql';
import { useLocation, useNavigate } from 'react-router-dom';
import useSaveCancelButtons from '../../hooks/use-save-cancel';
import { ReactComponent as Logo } from '../../assets/images/flexype-logo.svg';
import { ReactComponent as LoadingIcon } from '../../assets/images/loading.svg';
import ConfirmationDialog from '../shared/confirmation-dialog/confirmation-dialog';
import { ReactComponent as LogoWhite } from '../../assets/images/flexype-logo-white.svg';

export const Navbar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const routeLink = useSelector((state: RootState) => state.main.routeLink);
  const sideBarState = useSelector((state: RootState) => state.main.sideBarState);
  const pageProgress = useSelector((state: RootState) => state.main.pageProgress);
  const confirmationDialog = useSelector((state: RootState) => state.main.confirmationDialog);

  const [showAllStoresDialog, setShowAllStoreDialog] = useState<boolean>(false);

  const { data: merchantList } = useGetMerchantListQuery();
  const { showActions, setShowActions, onSave, onCancel, isSaveCancelLoading } = useSaveCancelButtons();
  const handleSidebarToggle = () => {
    dispatch(setSidebarState(!sideBarState));
  };

  window.onpopstate = function (e) {
    if (showActions) {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
      history.pushState(null, document.title, `${location.pathname}${location.hash}`);
      dispatch(setRouteLink(`${location.pathname}${location.hash}`));
      dispatch(setConfirmationDialog(true));
    } else {
      dispatch(setConfirmationDialog(false));
    }
  };

  return (
    <>
      <LoadingBar
        color="#2f72ff"
        height={3}
        progress={pageProgress}
        onLoaderFinished={() => dispatch(setPageProgress(0))}
      />

      <div
        className="fixed top-0 z-10 w-screen h-12"
        style={{
          backgroundColor: showActions ? 'rgba(51, 51, 51, 1)' : 'white'
        }}
      >
        <div className="lg:hidden flex h-full border-b-[1px]">
          <div className="w-full h-full flex items-center pl-3 pr-1">
            <div className="flex items-center justify-between w-full h-full">
              <div className="flex items-center gap-3">
                <Unicons.UilBars
                  className="cursor-pointer"
                  onClick={handleSidebarToggle}
                  color={`${showActions ? 'white' : 'black'}`}
                />
                {!showActions && <LogoWhite className="h-6 w-20" />}
              </div>

              {showActions && (
                <div className="flex gap-1 items-center ml-1 sm:ml-4 mr-auto sm:gap-2">
                  <Unicons.UilExclamationTriangle className="z-10 text-white h-5 w-5 sm:h-6 sm:w-6" />
                  <span className="text-white text-sm">Unsaved changes</span>
                </div>
              )}

              {!showActions ? (
                <DashboardAction
                  merchantList={merchantList?.getMerchantList || []}
                  setShowAllStoreDialog={setShowAllStoreDialog}
                />
              ) : (
                <div className="pb-0 gap-2 flex items-center mr-2 sm:mr-6 sm:gap-3">
                  <Button
                    variant="outline"
                    size="sm"
                    className="!h-[30px] bg-[rgba(51, 51, 51, 1)] text-white border-[1px] hover:bg-[rgba(51, 51, 51, 1)]"
                    onClick={() => onCancel()}
                  >
                    Cancel
                  </Button>
                  <Button variant="outline" size="sm" className="!h-[30px] min-w-16" onClick={() => onSave()}>
                    {isSaveCancelLoading ? (
                      <LoadingIcon height={16} className={'h-4 w-4 animate-spin text-black'} />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="items-center justify-between border-b-[1px] hidden lg:flex">
          <div>
            {showActions ? <LogoWhite className="h-8 w-24 my-2 mx-8" /> : <Logo className="h-8 w-24 my-2 mx-8" />}
          </div>

          {!showActions ? (
            <DashboardAction
              merchantList={merchantList?.getMerchantList || []}
              setShowAllStoreDialog={setShowAllStoreDialog}
            />
          ) : (
            <LayoutWrapper className="mr-[240px]">
              <div className="flex w-full justify-between">
                <div className="flex space-x-2 items-center ml-[90px]">
                  <Unicons.UilExclamationTriangle className="z-10 text-white h-6 w-6" />{' '}
                  <span className="text-white text-sm">Unsaved changes</span>{' '}
                </div>

                <div className="pb-0 space-x-3 flex items-center">
                  <Button
                    variant="outline"
                    size="md"
                    className="h-[30px] bg-[rgba(51, 51, 51, 1)] text-white border-[0.1px] hover:bg-[rgba(51, 51, 51, 1)]"
                    onClick={() => onCancel()}
                  >
                    Cancel
                  </Button>
                  <Button variant="outline" size="md" className="!h-[30px]" onClick={() => onSave()}>
                    {isSaveCancelLoading ? (
                      <LoadingIcon height={20} className={'h-4 w-4 animate-spin text-black'} />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </div>
              </div>
            </LayoutWrapper>
          )}
          <ConfirmationDialog
            showModal={confirmationDialog}
            setShowModal={setConfirmationDialog}
            onSave={() => {
              if (showActions) {
                dispatch(setCancelButtonClicked(true));
                setShowActions(false);
                if (history.length) {
                  navigate(routeLink);
                } else {
                  navigate(window.location.pathname);
                }
              }
              dispatch(setConfirmationDialog(false));
            }}
            onCancel={() => {
              dispatch(setConfirmationDialog(false));
              history.pushState(null, document.title, `${location.pathname}${location.hash}`);
            }}
            text={'This action will discard changes. Are you sure you want to continue?'}
            headerText="Discard changes"
            confirmButtonText="Discard"
            confirmActionVariant="destructive"
            loading={false}
          />
        </div>
      </div>

      <AllStoresDialog
        open={showAllStoresDialog}
        setOpen={setShowAllStoreDialog}
        merchantList={merchantList?.getMerchantList || []}
      />
    </>
  );
};
